export const BG_PRIMARY = '#FFFCF7';
export const BG_SECONDARY = '#DBD0C0';
export const TEXT_PRIMARY = '#1A1B1D';
export const TEXT_PRIMARY_LIGHTER = '#323030';
export const TEXT_SECONDARY = '#FEFEFE';

export const DEFAULT_BUTTON = '#7B5F53';

export const BG_SECONDARY_DARK = '#86550a';

// text decoration digunain untuk dekorasi dibelakang ayat &
// radio button rsvp ketika diselect
// button hover
export const TEXT_DECORATION = '#cdc1b1';

export const ALTERNATE_BUTTON_COLOR = '#7B5F53';
export const BTN_NAVIGATION_COLOR = '#997a5e';

export const HEADING_STYLES = {
  fontFamily: 'heading',
  fontWeight: 'light',
  fontSize: '4xl',
};

export const HEADING_ALTERNATIVE_STYLES = {
  fontFamily: 'headingAlternative',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontSize: 'md',
  textAlign: 'center',
};

export const HASHTAG_STYLE = {
  fontFamily: 'body',
  fontWeight: 'light',
  fontSize: '4xl',
  fontStyle: 'italic',
};

export const BUTTON_PROPS = {};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'light',
  size: 'sm',
  fontSize: 'xl',
  padding: '17px 40px',
  fontStyle: 'italic',
  type: 'button',
  bgColor: DEFAULT_BUTTON,
  color: BG_PRIMARY,
  fontFamily: 'body',
  borderRadius: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#1A1B1D',
};

export const LINK_STYLES = {
  color: '#CFB4B4',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
};
